import "$styles/index.css";
// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}";

// Mobile menu functionality
const initMobileMenu = () => {
  const menuButton = document.querySelector("#mobile-menu-button");
  const closeButton = document.querySelector("#mobile-menu-close-button");
  const mobileMenu = document.querySelector("#mobile-menu");

  if (menuButton && closeButton && mobileMenu) {
    menuButton.addEventListener("click", () => mobileMenu.classList.toggle("hidden"));
    closeButton.addEventListener("click", () => mobileMenu.classList.toggle("hidden"));
  }
};

// Text effects
import Lenis from 'lenis'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

const wrapElements = (elems, wrapType, wrapClass) => {
  elems.forEach((char) => {
    const wrapEl = document.createElement(wrapType);
    wrapEl.classList = wrapClass;
    char.parentNode.appendChild(wrapEl);
    wrapEl.appendChild(char);
  });
};

Splitting();

const lettersAndSymbols = [
  "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",
  "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
  "!", "@", "#", "$", "%", "^", "&", "*", "-", "_", "+", "=", ";",
  ":", "<", ">", ",",
];

const fx8Titles = [...document.querySelectorAll(".content__title[data-splitting][data-effect8]")];
const fx16Titles = [...document.querySelectorAll(".content__title[data-splitting][data-effect16]")];
const fx27Titles = [...document.querySelectorAll(".content__title[data-splitting][data-effect27]")];
const fx9Titles = [...document.querySelectorAll(".content__title[data-splitting][data-effect9]")];
const fx1Titles = [...document.querySelectorAll(".content__title[data-splitting][data-effect1]")];

// Lenis smooth scrolling
let lenis;

// Initialize Lenis smooth scrolling
const initSmoothScrolling = () => {
  lenis = new Lenis({
    lerp: 0.2,
    smoothWheel: true,
    duration: 1.2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  });

  lenis.on("scroll", () => ScrollTrigger.update());

  gsap.ticker.add((time) => {
    lenis.raf(time * 1000);
  });

  const scrollFn = (time) => {
    lenis.raf(time);
    requestAnimationFrame(scrollFn);
  };

  requestAnimationFrame(scrollFn);
};

// GSAP Scroll Triggers
const scroll = () => {
  // Vision section animation (effect1)
  fx1Titles.forEach((title) => {
    const chars = title.querySelectorAll(".char");
    
    chars.forEach((char, position) => {
      gsap.fromTo(
        char,
        {
          opacity: 0,
          scale: 0,
          y: -50,
          rotationX: -90,
        },
        {
          opacity: 1,
          scale: 1,
          y: 0,
          rotationX: 0,
          duration: 1,
          ease: "back.out",
          delay: position * 0.06,
          scrollTrigger: {
            trigger: title,
            start: "top bottom-=10%",
            end: "bottom top+=10%",
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  });

  fx8Titles.forEach((title) => {
    const chars = title.querySelectorAll(".char");

    chars.forEach((char, position) => {
      let initialHTML = char.innerHTML;

      gsap.fromTo(
        char,
        {
          opacity: 0,
        },
        {
          duration: 0.01,
          innerHTML: () => lettersAndSymbols[Math.floor(Math.random() * lettersAndSymbols.length)],
          repeat: 0,
          repeatRefresh: true,
          opacity: 1,
          repeatDelay: 0.01,
          delay: (position + 1) * 0.07,
          onComplete: () => gsap.set(char, { innerHTML: initialHTML, delay: 0.3 }),
          scrollTrigger: {
            trigger: title,
            start: "top bottom",
            end: "bottom center",
            toggleActions: "play resume resume reset",
            onEnter: () => gsap.set(char, { opacity: 0 }),
          },
        }
      );
    });
  });

  fx16Titles.forEach((title) => {
    gsap.fromTo(
      title,
      {
        transformOrigin: "0% 50%",
      },
      {
        ease: "none",
        rotate: 0,
        scrollTrigger: {
          trigger: title,
          start: "top bottom",
          end: "top top",
          scrub: true,
        },
      }
    );

    gsap.fromTo(
      title.querySelectorAll(".word"),
      {
        "will-change": "opacity",
        opacity: 0.1,
      },
      {
        ease: "none",
        opacity: 1,
        stagger: 0.05,
        scrollTrigger: {
          trigger: title,
          start: "top bottom-=25%",
          end: "center top+=20%",
          scrub: true,
        },
      }
    );
  });

  fx27Titles.forEach((title) => {
    const words = [...title.querySelectorAll(".word")];

    words.forEach((word) => gsap.set(word.parentNode, { perspective: 1000 }));

    gsap.fromTo(
      words,
      {
        "will-change": "opacity, transform",
        z: () => gsap.utils.random(500, 950),
        opacity: 0,
        xPercent: (pos) => gsap.utils.random(-100, 100),
        yPercent: (pos) => gsap.utils.random(-10, 10),
        rotationX: () => gsap.utils.random(-90, 90),
      },
      {
        ease: "expo",
        opacity: 1,
        rotationX: 0,
        rotationY: 0,
        xPercent: 0,
        yPercent: 0,
        z: 0,
        scrollTrigger: {
          trigger: title,
          start: "center center",
          end: "+=300%",
          scrub: true,
          pin: title.parentNode,
        },
        stagger: {
          each: 0.006,
          from: "random",
        },
      }
    );
  });

  fx9Titles.forEach((title) => {
    const words = title.querySelectorAll(".word");

    for (const word of words) {
      const chars = word.querySelectorAll(".char");

      gsap.fromTo(
        chars,
        {
          "will-change": "transform",
          scaleX: 0,
          x: (_, target) => window.innerWidth / 2 - target.offsetLeft - target.offsetWidth / 2,
        },
        {
          ease: "power1.inOut",
          scaleX: 1,
          x: 0,
          scrollTrigger: {
            trigger: word,
            start: "top bottom",
            end: "top top",
            scrub: true,
          },
        }
      );
    }
  });
};

// Smooth scroll to section on navigation click
const navigationLinks = document.querySelectorAll(".navigation__link");
navigationLinks.forEach((link) => {
  link.addEventListener("click", (event) => {
    event.preventDefault();
    const href = link.getAttribute("href");
    lenis.scrollTo(href);
  });
});

document.addEventListener("DOMContentLoaded", () => {
  // Initialize mobile menu
  initMobileMenu();
  // Lenis (smooth scrolling)
  initSmoothScrolling();
  // GSAP Scroll Triggers
  scroll();
});